import React,  {useState, useEffect} from "react";
import SearchResultPage from "../../../templates/search-results-template";
import { UserObjectStoreProvider, useUserObjectState, useAuthState, getUserobjectData } from "@starberry/myaccount-website-utils";

const SearchResult = (props) => {

    // For myaccount
    useEffect(() => {
        if (isAuthenticated) {
          const getUserObjects = async () => {
              try {
                  const userObjects = await getUserobjectData()
                  setUserObjects(userObjects.data.data)
              } catch (e) {
                  console.log("e:", e)
              }
          }
          getUserObjects()
        }
    }, [])

    const [userObjects, setUserObjects] = useState({});
    const { state: authState, services: authServices } = useAuthState()
    const isAuthenticated = authServices.isAuthenticated()


    const fullpath = props.location.pathname
    const location = props.location
    const fullpathone = props.location

    return (
        <React.Fragment>
            <SearchResultPage 
                locationname="/property/for-sale/" 
                pcategorytype="residential" 
                multipcategorytype = {['residential']}
                ptype="sales" 
                ptypetag="for-sale" 
                pstatustype={["For Sale", "Sold Subject to Contract"]}
                fullpathone={fullpathone} 
                fullpathname={fullpath}
                {...props}
                psearch="Sales"
                userObjects={userObjects}
            />
        </React.Fragment>
    );
};

const SearchResultResiSales = (props) => {
    return(
        <UserObjectStoreProvider>
            <SearchResult {...props} />
        </UserObjectStoreProvider>
    )
}

export default SearchResultResiSales;